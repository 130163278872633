<template>
  <div>
    <div class="d-flex align-items-center justify-content-center ">
      <b-img
        v-if="cropped"
        width="200"
        class="cursor-pointer"
        thumbnail
        :src="cropped"
        @click="chooseFile()"
      />
      <b-img
        v-else
        key="img2"
        width="200"
        class="cursor-pointer"
        thumbnail
        :src="model.logo != null ? model.logo : require(`@/assets/images/logo/logo_square.png`)"
        @click="chooseFile()"
      />
    </div>
    <b-form-file
      id="fileInput"
      class="d-none"
      placeholder="Añade una imagen"
      drop-placeholder="Arrastrar aquí..."
      accept="image/*"
      @change="croppie"
    />
    <b-modal
      id="croppieModal"
      size="xl"
      @ok="handleEmit()"
    >
      <vue-croppie
        ref="cropRef"
        :enable-resize="false"
        :enable-orientation="false"
        :mouse-wheel-zoom="true"
        :boundary="boundarySize"
        :viewport="viewportSize"
        @result="result"
        @update="crop"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BImg,
  BFormFile,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BFormFile,
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
    viewportSize: {
      type: Object,
      default: null,
    },
    boundarySize: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      cropped: null,
    }
  },
  computed: {
    ...mapGetters([
      'apiUrl',
    ]),
  },
  methods: {
    croppie(e) {
      this.$bvModal.show('croppieModal')
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      const reader = new FileReader()
      reader.onload = file => {
        this.$refs.cropRef.bind({
          url: file.target.result,
        })
      }
      reader.readAsDataURL(files[0])
    },
    crop() {
      const options = {
        format: 'png',
        square: false,
      }
      this.$refs.cropRef.result(options, output => {
        const newLogo = {
          data: null,
        }
        this.model.logo = newLogo
        this.cropped = this.model.logo.data
        this.cropped = output
      })
    },

    result(output) {
      this.cropped = output
    },

    chooseFile() {
      document.getElementById('fileInput').click()
    },

    handleEmit() {
      let cropCopy

      if (!this.cropped) {
        cropCopy = {
          data: null,
        }
      } else {
        cropCopy = {
          data: this.cropped,
        }
      }
      this.$emit('croppedImage', cropCopy)
    },
    imgToDisplay() { },
  },
}
</script>

<style lang="scss" scoped>
.cursor-pointer{
  cursor: pointer;
}
</style>
